import React, { useState } from "react";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faList,
  faSignOutAlt,
  faUser,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/scss/layout/_sidebar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import QuestionModal from "../QuestionModal";
import axios from "axios";
import Config from "../../Config/config";
import { useAuthToken } from "../../Utilities/Auth/Token";
import { useDispatch } from "react-redux";
import { logout } from "../../services/Actions/userSlice";
import { LogoutAndClearProfile } from "../../services/Actions/logoutAction";
import { useSelector } from "react-redux";

const SidebarTalent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("");
  const token = useAuthToken();
  const [modalOpen, setModalOpen] = useState(false);
  const isAIMLOn = false;

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleCompanyProfileClick = () => {
    navigate("/talent/profile");
    setActiveItem("talent/profile");
    // Add your desired action here
  };

  const handleAppliedJobClick = () => {
    setActiveItem("talent/appliedjob");
    navigate("/talent/applied-job");

    // Add your desired action here
  };

  const handleMyresumeClick = () => {
    setActiveItem("talent/myresume");
    navigate("/talent/my-resume");

    // Add your desired action here
  };

  const handleChatAssistant = () => {
    setActiveItem("talent/chat-assistant");
    navigate("/talent/chat-assistant");

    // Add your desired action here
  };

  const handleLogoutClick = async () => {
    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/talent/logout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const FB = window.FB;
      if (FB) {
        console.log("Logging out from Facebook...");
        FB.logout(function (response) {
          // Log out successful
          console.log("User logged out from Facebook");
        });
      }

      dispatch(LogoutAndClearProfile());
      localStorage.clear(); // Clear all local storage
      sessionStorage.clear();
      navigate("/");
    } catch (error) {
      dispatch(LogoutAndClearProfile());
      localStorage.clear();
      sessionStorage.clear();
      console.log(error);
      navigate("/");
    }

    // console.log(response);
    // localStorage.clear(); // Clear all local storage
    // const cevent = new CustomEvent("onChangeUserType", {
    //   detail: null,
    // });
    // document.dispatchEvent(cevent);
    // navigate("/logintalent");
  };

  const isActiveItem = (routeName) => {
    return location.pathname === `/${routeName}`;
  };

  return (
    <div className="alignment">
      <Container className="side-bar">
        <ListGroup>
          <ListGroupItem
            action
            tag="a"
            onClick={handleCompanyProfileClick}
            active={isActiveItem("talent/profile")}
          >
            <FontAwesomeIcon className="sidebar-icon" icon={faUser} />
            Profile
          </ListGroupItem>
          <ListGroupItem
            action
            tag="a"
            onClick={handleMyresumeClick}
            active={isActiveItem("talent/my-resume")}
          >
            <FontAwesomeIcon className="sidebar-icon" icon={faBriefcase} />
            My Resume
          </ListGroupItem>
          {isAIMLOn && (
            <ListGroupItem
              action
              tag="a"
              onClick={handleChatAssistant}
              active={isActiveItem("talent/chat-assistant")}
            >
              <FontAwesomeIcon className="sidebar-icon" icon={faBriefcase} />
              Chat Assistant
            </ListGroupItem>
          )}

          <ListGroupItem
            action
            tag="a"
            onClick={handleAppliedJobClick}
            active={isActiveItem("talent/applied-job")}
          >
            <FontAwesomeIcon className="sidebar-icon" icon={faList} />
            Applied Job
          </ListGroupItem>
          <ListGroupItem action tag="a" onClick={toggleModal}>
            <FontAwesomeIcon className="sidebar-icon" icon={faSignOutAlt} />
            Logout
          </ListGroupItem>

          <QuestionModal
            modalOpen={modalOpen}
            toggleModal={toggleModal}
            // iconImage={QuestionIcon}
            modalLabel="Oops"
            modalSublabel="Are you sure you want to logout?"
            confirmButtonText="Yes, log me out"
            onConfirm={handleLogoutClick}
            onCancel={toggleModal}
          />
        </ListGroup>
      </Container>
    </div>
  );
};

export default SidebarTalent;
